import React, { useContext } from "react";
import styled from "styled-components";
import { Container } from "react-bootstrap";
import layoutContext from "../../../context/LayoutContext";
import { Link } from "react-router-dom";
import { PRIMARY } from "../../../vars/vars";

const SmallNavigation = () => {
  const context = useContext(layoutContext);

  return (
    <StyledSmallNavigation>
      <Container>
        <StyledLink>
          <Link to={"/"}>Strona główna</Link>
        </StyledLink>
        {context.smallNavText ? (
          <StyledLink>{context.smallNavText}</StyledLink>
        ) : null}
      </Container>
    </StyledSmallNavigation>
  );
};

export default SmallNavigation;

const StyledSmallNavigation = styled.section`
  background-color: #f5f5f5;
  padding: 92px 0 22px;
`;

const StyledLink = styled.div`
  font-size: 12px;
  color: #333333;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  line-height: 22px;
  position: relative;
  padding: 0 8px 0 18px;
  border-left: 1px solid #ddd;
  height: 25px;
  display: inline-block;

  &:after {
    border-right: 1px solid #ddd;
    border-top: 1px solid #ddd;
    content: "";
    display: inline-block;
    height: 17px;
    position: absolute;
    top: 3px;
    transform: rotate(45deg);
    width: 17px;
  }

  &:not(:first-of-type) {
    border-left: none;
    margin-left: 5px;

    &:before {
      border-right: 1px solid #ddd;
      border-top: 1px solid #ddd;
      left: -8px;
      content: "";
      display: inline-block;
      height: 17px;
      position: absolute;
      top: 3px;
      transform: rotate(45deg);
      width: 17px;
    }
  }

  &:first-of-type {
    padding-left: 10px;

    &:hover {
      border-top: 1px solid ${PRIMARY};
      border-bottom: 1px solid ${PRIMARY};
      border-left: 1px solid ${PRIMARY};

      &:after {
        border-right: 1px solid ${PRIMARY};
        border-top: 1px solid ${PRIMARY};
      }
    }
  }
`;
